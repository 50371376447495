import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          {/* <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Gurdeep Jain </span>
            from <span className="purple"> Pune, India.</span>
            <br />
            I am currently employed as a software developer at Cybage Software.
            <br />
            I have completed my graduation in BE-IT.
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p> */}
          <p className="home-about-body">
          Hello! I'm  <span className="purple">Gurdeep Jain</span>, a dedicated and enthusiastic Senior Software Engineer based in the bustling city of Pune. 
          I am currently enhancing my skills and contributing to innovative projects at <span className="purple">Cybage Software</span>.
          <br />
          <br />
          The foundation of my career in the tech sector has been my Bachelor of <span className="purple">Information Technology (B.E.)</span> degree. I have a strong grasp of software development and an excellent attention to detail thanks to my professional experience and academic background.
          <br />
          <br />
          Outside of my professional life, I am an avid <span className="purple">traveler</span> who loves exploring new destinations and immersing myself in different cultures. <span className="purple">Hiking</span> through scenic trails is one of my favorite pastimes, offering both adventure and tranquility. When I'm indoors, I enjoy the thrill of <span className="purple">PlayStation</span> games and the excitement of playing football with friends. These hobbies not only keep me active but also inspire creativity and provide a well-rounded balance to my life.
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Games
            </li>
            <li className="about-activity">
              <ImPointRight /> Hiking
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "The only way to do great work is to love what you do" - Steve Jobs
          </p>
          <footer className="blockquote-footer"></footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
